.createButton {
  margin-right: 8px;
}

.closeButton {
  margin-right: 8px;
}

.dtgCampaignSwitch {
  margin-top: 30px !important;
}

.divider {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.formItems {
  flex: 1;
  overflow: auto;
  padding: 24px;
}

.buttonsContainer {
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
}

.button {
  background-color: #323232 !important;
  border-radius: 7px !important;
  border: 2px solid transparent;
}

.button:hover {
  border: 2px solid gray;
}
