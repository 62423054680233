// TODO - remove becouse of legacy

.templatesContainer {
  display: flex;
  flex-direction: row;
}

.card {
  width: 100%;
}

.templatesListContainer {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-around;
  // align-items: flex-start;
}

.leftContainer {
  display: flex;
  flex-direction: column;
}

.templatesListItem,
.selectedTemplatesListItem {
  display: flex;
  flex-direction: column;
  border: 1px solid #f0f2f5;
  width: 180px;
  height: 220px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.selectedTemplatesListItem {
  border: 2px solid #40a9ff;
}

.templatesListItemImageContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.templatesListItemImage {
  object-fit: scale-down;
  max-width: 100%;
}

.templatesListItemText {
  text-align: center;
  font-weight: bold;
  line-height: normal;
  word-wrap: break-word;
}

.closeButton {
  margin-right: 10px;
}

.pictureIcon {
  display: flex !important;
  justify-content: center;
  margin-top: 40px;
  font-size: 40px;
}

.templatesPreviewContainer {
  padding-left: 10px;
  flex: 1;
  display: flex;
}

.leftPreviewContainer {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.rightPreviewContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.previewImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin: 15px;
  padding: 15px;
  border: 1px solid #f0f2f5;
}

.previewImage {
  object-fit: contain;
  overflow: hidden;
  flex: 1;
  width: 100%;
  height: 100%;
}

.pagination {
  display: flex;
  justify-content: center;
}
