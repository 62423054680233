.listContainer {
  display: flex;
  width: 100%;
}

.leftColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.rightColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.listItem {
  padding: 5px 0;
}
