.contentComponent {
  padding: 15px 15px 40px;
  flex: unset !important;
}

.tableContainer {
  width: 100%;
  height: 100%;
  display: flex;
}
