// TODO - remove becouse of legacy

.contentComponent {
  padding: 1.5rem;
}

.tableContainer {
  width: 100%;
  height: 100%;
  display: flex;
}
