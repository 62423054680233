.contentComponent {
  padding: 15px;
}

.extraContent {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.createButton {
  margin-left: 10px;
}
