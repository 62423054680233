.form {
  flex: 1;
}

.userListContainer {
  flex: initial !important;
  margin-bottom: 10px;
}

.contentComponent {
  flex: initial !important;
  padding: 0 15px 15px;
}

.pluginContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.plugin {
  width: 49% !important;
}

.group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.groupItem {
  width: 49% !important;
}

.inputField {
  width: 100% !important;
}
