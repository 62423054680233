.tableContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px;
}

.cardTitle {
  font-weight: bold;
}
