.listContainer {
  display: flex;
  border: 1px solid #f0f0f0;
  padding: 10px;
  margin: 20px !important;
  max-width: 100%;
}

.leftColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.rightColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.listItem {
  padding: 5px 0;
}

.tabsContainer {
  overflow: auto !important;
  margin: 0 20px 0 20px !important;
}

.baseContainer {
  position: relative;
}

.blockPage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(220, 220, 220, 0.95);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blockPageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.blockPageTitle {
  font-size: 20px;
  font-weight: 500;
  span {
    color: #1890ff;
    text-decoration: underline;
    cursor: pointer;
  }
}
