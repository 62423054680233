.contentComponent {
  padding: 15px;
}

.totalRow {
  background: #fafafa;
}

.switchContainer {
  display: flex;
  align-items: center;
  margin-right: 15px;

  > span {
    margin-right: 10px;
  }
}

.extraContainer {
  display: flex;
}