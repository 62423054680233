// TODO - remove becouse of legacy

.buildsContainer {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.versionsContainer {
  width: 200px;
  font-size: 16px;
}

.buildLogsContainer {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #242424;
  padding: 5px;
  overflow-y: scroll;
  min-height: 600px;
}

.title {
  font-size: 16px;
}

.buildVersionContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.buildVersion {
  cursor: pointer;
  padding-left: 20px;
}
