@import '~antd/dist/antd.css';
@import '~mrx-notification/assets/index.css';

#root {
  width: 100%;
  height: 100%;
}

.google-visualization-tooltip {
  pointer-events: none;
}

.mrx-notification-notice-description {
  white-space: pre-wrap;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
