// TODO - remove becouse of legacy

.form {
  width: 100%;
}

.card {
  margin: 0 20px !important;
}

.saveButton {
  margin-right: 10px;
}

.group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.groupItem {
  width: 49% !important;
}

.inputField {
  width: 100% !important;
}

.advancedButton {
  margin-bottom: 15px;
}
