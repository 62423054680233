.list {
  min-height: 100%;
  background: #fff;
}

.pageHeader {
  border: 1px solid rgb(235, 237, 240);
}

.settingsField {
  margin-top: 10px;
}

.addButton {
  text-transform: uppercase;
}

.tableContainer {
  margin-top: 10px;
}