// TODO - remove becouse of legacy

.image {
  width: 195px;
  height: 195px;
  margin-bottom: 10px;
  object-fit: contain;
}

.uploadFaviconContainer {
  display: flex;
  flex-direction: column;
}
