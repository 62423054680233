.pluginSettingContainer {
  position: relative;
  width: 250px;
  height: 250px;
  border: 1px solid #f0f2f5;
  margin-bottom: 20px;
  margin-right: 20px;
}

.pluginSettingContainer:hover .removeButtonHidden {
  display: inline-flex !important;
}

.pluginSetting {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.removeButton, .removeButtonHidden {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 10;
}

.removeButtonHidden {
  display: none !important;
}

.pluginLogo {
  max-width: 100%;
  height: 120px;
  object-fit: contain;
  margin-bottom: 10px;
}
