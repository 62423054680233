.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 70vh;
}

.cardTitle {
  font-weight: bold;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.cancelButton {
  margin-right: 8px;
}

.uploadSettingImageContainer {
  display: flex;
  width: 100%;
}
