.container {
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.contentComponent {
  display: flex;
  padding: 16px 0;
  flex-grow: 1;
}

.contentLeft {
  width: 50%;
  padding: 0 8px 0 16px;
}

.contentRight {
  width: 50%;
  padding: 0 16px 0 8px;
}

.cancelButton {
  color: black;
  margin-right: 10px;
}

.labeledItem {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 10px;
}

.typography {
  color: #000;
  padding-bottom: 8px;
}

.mainImagePreviewContainer > div > div {
  width: 450px;
  height: 200px;
}

.bannerImagePreviewContainer > div > div {
  width: 450px;
  height: 200px;
}

.footer {
  display: flex;
  justify-content: flex-end;

  button:nth-child(1) {
    margin-right: 10px;
  }
}
