.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.settingsRow {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 24px;;
}

.settingItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
}

.settingTitle {
  margin-right: 8px;
  font-weight: bold;
}

.saveButton {
  margin-right: 8px;
}
