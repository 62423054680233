.registerFormContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow: auto;

  @media screen and (max-width: 700px) {
    padding: 50px 0 20px;
  }
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  padding-bottom: 50px;

  @media screen and (max-width: 700px) {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}

.logo {
  @media screen and (max-width: 700px) {
    width: 300px;
  }
}

.registerForm {
  min-width: 500px;
  max-width: 500px;

  @media screen and (max-width: 700px) {
    min-width: 300px;
    max-width: 300px;
  }
}

.formItem {
  @media screen and (max-width: 700px) {
    margin-bottom: 10px !important;
  }
}

.registerFormButton {
  width: 100% !important;
}
