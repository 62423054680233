// TODO - remove becouse of legacy

.cancelButton {
  margin-right: 8px;
}

.divider {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.85) !important;
}
