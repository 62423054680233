.contentComponent {
  padding: 1.5rem;
}

.baseContainer {
  display: flex;
  flex-wrap: wrap;
}

.loadMoreButton {
  width: 200px;
  margin: 0 auto 20px;
}
