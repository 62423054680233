// TODO - remove becouse of legacy

.ad-spend-report {
  background: #fff;
  min-height: 100%;
  padding: 10px;
}

.tableContainer {
  width: 100%;
  overflow-x: auto;
}

.select {
  min-width: 250px;
  max-width: 550px;
}
