// TODO - remove becouse of legacy

.root {
  margin-bottom: 20px;

  &:hover span {
    opacity: 1 !important;
  }
}

.fieldContainer {
  display: flex;
  align-items: center;
}
