.contentComponent {
  padding: 15px;
  flex: 1 !important;
  overflow: auto;
  margin-bottom: 30px;
}

.tableContainer {
  width: 100%;
  height: 100%;
  display: flex;
}

.extraContainer {
  display: flex;
  align-items: center;
}

.extraContent {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.extraLabel {
  margin: 0 10px;
}

.layoutContainer {
  flex: initial !important;
}

.createButton {
  margin-left: 10px;
}
