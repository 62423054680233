.listContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #323232;
  background-color: #001529 !important;
  z-index: 1;
}

.avatar {
  color: white !important;
  background-color: #002140 !important;
  margin: 5px !important;
  border-radius: 7px !important;
  border: 2px solid transparent;
}

.avatar:hover {
  border: 2px solid gray;
}
