.contentComponent {
  padding: 15px;
}

.extraContent {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.createButton {
  margin-left: 10px;
}

.popupMenu {
  display: flex;
  flex-direction: column;
}

.menuItem {
  padding-bottom: 5px;
  cursor: pointer;
}
