.seoBlock {
  padding: 20px 20px 0;
  border: 1px solid #dddddd;
  border-radius: 5px;
  display: flex;
  margin-bottom: 20px;
}

.seoBlockWithoutBorder {
  display: flex;
}

.seoBlockLeftPart {
  display: flex;
  flex-direction: column;
  width: 49%;
}

.seoBlockRightPart {
  display: flex;
  flex-direction: column;
  width: 49%;
  // margin-top: 55px;
  border-left: 1px solid #dddddd;
  padding-left: 20px;
  margin-bottom: 15px;
}

.seoUrlInput > span {
  margin-right: 0px;
}

.seoCustomizeButton {
  cursor: pointer;
  margin-bottom: 30px;
}

.labeledItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-right: 20px;
}

.examplePageTitle {
  margin-top: 16px;
  font-size: 22px;
  font-weight: 400;
  line-height: 22px;
  color: #1717a4;
  margin-bottom: 7px;
  cursor: pointer;
}

.exampleUrl {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #2a642a;
  cursor: pointer;
}

.exampleDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #545454;
  margin-top: 7px;
}
