.totalRow {
  background: #fafafa;
}

.uploadButton {
  position: relative;
  height: 50px;
  padding: 0;
  font-size: 12px;
  background: #f1f1f1;
  border: 1px solid #e8e8e8;
  width: 100%;
}

.uploader {
  position: absolute;
  top: 0;
  left: 0;
  max-height: 50px;
  width: 100%;
  opacity: 0;
  overflow: hidden;
}

.artworkPreview {
  max-width: 50px;
  max-height: 50px;
  margin-bottom: 6px;
  box-shadow: 0 0 6px -3px rgba(0, 0, 0, 1);

  &:hover {
    box-shadow: none;
  }
}
