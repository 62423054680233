.root {
  display: flex;
  flex-direction: row;
}

.labeledInput {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-right: 20px;
}

.checkbox {
  margin: auto 0;
  padding-bottom: 5px;
}
