.cellth {
  cursor: pointer;
}

.cellContent {
  display: flex;
  justify-content: space-between;
}

.cellImage {
    max-width: 1rem;
}
