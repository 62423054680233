.contentComponent {
  padding: 15px;
}

.listContainer {
  display: flex;
  width: 100%;
  border: 1px solid #f0f0f0;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 25px;
  color: black;
  font-weight: bold;
}

.listContainerRegular {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid #f0f0f0;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 25px;
  color: black;
  gap: 10px 0;
}

.leftColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.rightColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.listItem {
  padding: 5px 0;
}

.subscriptionTable {
  margin-top: 25px;
}

.manageButtons {
  display: flex;
  margin-bottom: 10px;
  gap: 10px;
}

.editCustomer:hover {
  opacity: 0.7;
}
