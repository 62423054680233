.wrapper {
    background: #e2f4ff;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0 0 12px -7px rgba(0,0,0,0.75);
}

.artworkPreviewContainer {
    width: 100%;
    display: flex;
    box-shadow: 0 0 12px -7px rgba(0,0,0,0.75);
    padding: 6px 20px 20px;
    border-radius: 20px;
    margin-bottom: 10px;
    gap: 20px;
}

.artworkContainer {
    border: 2px dashed #ad3636;
    background: #808080;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
        border: 2px solid #37b672;
    }
}

.downloadButton {
    margin-top: 10px;
}

.artworkUploadContainer {
    display: flex;
    width: 100%;
    gap: 20px;
}

.artworkUploader {
    flex: 1;
    text-align: center;
}

.artworkPreview {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}
