.loginFormContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow: auto;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  padding-bottom: 4rem;
}

.loginForm {
  min-width: 350px;
}

.loginFormButton {
  width: 100% !important;
}

.rememberBox {
  display: flex;
  justify-content: space-between;
}
