.createButton {
  margin-right: 8px;
}

.buttonContainer {
  margin: 5px;
}

.button {
  background-color: #323232 !important;
  border-radius: 7px !important;
  border: 2px solid transparent;
}

.button:hover {
  border: 2px solid gray;
}
