// TODO - remove becouse of legacy

.contentComponent {
  margin-top: 10px;
}

.button {
  margin: 0 8px;
}

.headerActionsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contentContainer {
  display: flex;
  width: 100%;
  height: 100%;
}

.buildLogsContainer {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #242424;
  padding: 5px;
  overflow-y: scroll;
}

.tabsContainer {
  width: 100%;
  margin: 8px;
}

.menuItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.versionInfo {
  padding-right: 20px;
  font-weight: 700;
  font-size: 16px;
}
