.listContainer {
  display: flex;
  width: 100%;
  border: 1px solid #f0f0f0;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 25px;
}

.leftColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.rightColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.listItem {
  padding: 5px 0;
}

.listItemTrack {
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
}

.listItemTrack:hover .editIcon {
  display: flex;
}

.editIcon {
  display: none;
}

.tabsContainer {
  overflow: auto !important;
}

.reachOutButton {
  background: #389e0d !important;
  border-color: #389e0d !important;

  &:hover, &:active, &:focus {
    background: #389e0d !important;
    border-color: #389e0d !important;
    opacity: 0.7 !important;
  }
}

.resolveButton {
  background: #fa8c16 !important;
  border-color: #fa8c16 !important;

  &:hover, &:active, &:focus {
    background: #fa8c16 !important;
    border-color: #fa8c16 !important;
    opacity: 0.7 !important;
  }
}

.reachedOutOrder {
  background: #fff7e6 !important;
}
