.root {
  display: flex;
  width: 100%;
}

.frameContainer {
  display: flex;
  flex: 3;
}

.settingsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 250px;
}

.switchContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 20px;
  border: 1px solid #f0f0f0;
  border-bottom: none;
}

.switch {
  margin-top: 10px !important;
}
