.root {
  width: 100%;
  height: 100%;
  padding: 8px;
  display: flex;
  flex: 1;
  overflow: auto;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.createButton {
  margin: 0 8px;
}

.closeButton {
  margin-right: 8px;
}

.tabs {
  width: 100%;
  overflow: auto;
}

.htmlTab {
  min-height: 100%;
}
