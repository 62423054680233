.overview-report-list__column-hidden {
  display: none;
}

.ant-table-row {
  font-weight: bold;
  background-color: #fafafa;
}

.campaignsCell {
  min-width: 180px;
}

.totalCell {
  text-align: end !important;
}
