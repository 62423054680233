.contentComponent {
  display: flex;
  flex: 1;
  padding: 15px;
  justify-content: flex-start;
  flex-direction: column;
}

.reportSwitch {
  align-self: flex-end;
}

.emptyImage {
  padding-top: 50px;
}

.chartContainer {
  display: flex;
  flex: 1;
}

.tableContainer {
  padding-top: 15px;
}

.select {
  width: 200px;
  margin-left: 10px;
}

.extraContainer {
  display: flex;
}
