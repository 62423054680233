.contentComponent {
  padding: 1.5rem;
}

.tableContainer {
  width: 100%;
  height: 100%;
  display: flex;
}

.rangePicker {
  width: 100%;
}

.drawerFooter {
  padding: 10px 16px;
  display: flex;
  justify-content: flex-end;
}

.cancelButton {
  margin-right: 8px;
}
