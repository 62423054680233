.uploadSettingVideoContainer {
  display: flex;
  flex-direction: column;
}

.video {
  width: 195px;
  height: 195px;
  margin-bottom: 10px;
  object-fit: contain;
}

.removeButton {
  margin-left: 15px;
}

.uploadButton {
  width: 150px;
}
