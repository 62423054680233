.createButton {
  margin-top: 10px;
}

.closeButton {
  margin-right: 8px;
}

.deleteButton {
  margin-left: 10px !important;
}

.rowContainer {
  flex-flow: row nowrap !important;
  align-items: baseline;
}
