.root {
  width: 100%;
  height: 100%;
  padding: 8px;
  display: flex;
  flex: 1;
  overflow: auto;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.createButton {
  margin: 0 8px;
}

.closeButton {
  margin-right: 8px;
}

.tabs {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.htmlTab {
  min-height: 100%;
}

.switchContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  padding-left: 24px;
}

.switch {
  margin-top: 10px !important;
}
