.contentComponent {
    padding: 15px;
    background-color: white;
}

.tableContainer {
    width: 100%;
    height: 100%;
    display: flex;
}

.root {
    background: #fff !important;
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
}
