.totalRow {
  background: #fafafa;
}

.disabledRow {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.4)
}

.attributesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.buttonsContainer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
}

.cancelButton {
  margin-right: 8px;
}
