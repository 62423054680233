// TODO - remove becouse of legacy

.stepsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
}

.steps {
  padding: 20px 50px 20px 30px !important;
}

.baseContainer {
  position: relative;
}

.blockPage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(220, 220, 220, 0.95);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blockPageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.blockPageTitle {
  font-size: 20px;
  font-weight: 500;
  span {
    color: #1890ff;
    text-decoration: underline;
    cursor: pointer;
  }
}
