// TODO - remove becouse of legacy

.contentComponent {
  padding: 1.5rem;
}

.iconContainer {
  display: flex;
}

.warningIcon {
  font-size: 20px;
  padding: 0 5px;
}

.status_active {
  color: #007a00;
}

.status_archived {
  color: #adadad;
}

.ant-table-cell {
  padding: 10px;
}
