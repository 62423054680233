.contentComponent {
  padding: 1.5rem;
}

.tableContainer {
  width: 100%;
  height: 100%;
  display: flex;
}

.rangePicker {
  width: 100%;
}