.content {
  padding: 20px;
}

.content,
.tabs,
.tabPane {
  min-height: 100%;
}

.detailsTab {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  margin-bottom: 20px;
}

.row {
  display: flex;
  align-items: center;
  width: 100%;
}

.rowItem {
  display: flex;
  width: 50%;
}

.title {
  font-weight: 700;
  margin-right: 8px;
}

.hellCrutch {
  flex-grow: 1;
  height: 100%;
}
