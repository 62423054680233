span.deleteButton {
  cursor: pointer;

  &[data-required='true'] {
    opacity: 0;
    cursor: default;
  }
}

.attributesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  align-items: center;
}
