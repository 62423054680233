.productFormBlock {
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.productFormRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.productFormRowItem {
  width: 49%;
}

.productFormPriceRow {
  display: flex;
}

.moreOptionBtn {
  width: 138px;
  color: black !important;
  display: flex;
}

.saveButton {
  margin-right: 10px;
}

.variantOptionInput {
  width: 200px !important;
}

.labeledItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-right: 20px;
}

.optionsContainer {
  position: relative;
  margin-top: 8px;
}

div.colorSelect {
  width: 200px !important;
  margin-top: 30px;
}

.colorManual {
  margin-top: 34px;
  margin-left: 50px;
}

.deleteTwoTone {
  position: absolute;
  top: 30px;
  left: 210px;
}

.colorManual[data-checked='true'] {
  margin-left: 42px;
}

.productFormOptionBlock {
  margin-top: 24px;
}

.previewBlockItemTitle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.previewBlockItemName {
  width: 368px;
  padding: 7px 0px 7px 10px;
  background-color: #dddddd;
  margin-right: 10px;
}
