.rowContainer {
  width: 100%;
  flex-flow: row nowrap !important;
  align-items: baseline;
}

.rowNumberContainer {
  padding-right: 5px;
}

.colContainer {
  width: 100%;
  padding-right: 10px;
}

.rowContainer .removeButtonContainer {
  display: none;
}

.rowContainer:hover .removeButtonContainer {
  display: flex;
}

.saveButton {
  margin-top: 10px !important;
}

.image {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
  object-fit: contain;
}
