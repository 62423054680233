.contentComponent {
  padding: 15px;
  overflow: auto;
  margin-bottom: 30px;
}

.container {
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.labeledItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.typography {
  color: #000;
  padding-bottom: 8px;
}

.footer {
  display: flex;
  justify-content: flex-end;

  button:nth-child(1) {
    margin-right: 10px;
  }
}

.activeSwitch {
  display: flex;

  article {
    margin-left: 10px;
  }
}

.image {
  width: 195px;
  height: 195px;
  margin-bottom: 10px;
  object-fit: contain;
}

.examplePageTitle {
  margin-top: 16px;
  font-size: 22px;
  font-weight: 400;
  line-height: 22px;
  color: #1717a4;
  margin-bottom: 7px;
}

.exampleUrl {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #2a642a;
}

.exampleDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #545454;
  margin-top: 7px;
}
