.root {
  display: flex;
  width: 100%;
}

.frameContainer {
  display: flex;
  flex: 3;
}

.settingsContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 250px;
}

.attributesCard {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.attributesTitle {
  font-weight: bold;
}
