.codeEditor {
  position: relative;
  width: 100%;
  height: 100%;
}

.paramContainer {
  margin-bottom: 12px;

  label {
    cursor: pointer;
    margin-bottom: 40px;
  }
}

.openDrawerIcon {
  position: absolute;
  right: 16px;
  top: 0;
  z-index: 1;
}
