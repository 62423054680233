.titleContainer {
  display: flex;
  align-items: center;
}

.title {
  margin-right: 8px;
  font-weight: bold;
}

.subtitle {
  margin-right: 8px;
  font-weight: semibold;
}
