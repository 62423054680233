.cancelButton {
  margin-right: 8px;
}

.attributesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}
