.productFormBlock {
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.productFormRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.productFormRowItem {
  width: 49%;
}
