.cardsContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

.cardRate {
  width: 15%;
}

.salesReportContaier {
  display: flex;
}

.salesReportLink {
  margin-top: 20px;
}

.cardBottom {
  width: 30%;
}

.cardDonut {
  width: 30%;
}

.donutChart {
  display: flex;
  justify-content: center;
}
