// TODO - remove becouse of legacy

.contentComponent {
    padding: 15px;
}

.tableContainer {
    width: 100%;
    height: 100%;
    display: flex;
}

.totalRow {
    background: #fafafa;
}

.campaignSelect {
    width: 200px;
    display: inline-block;
}
