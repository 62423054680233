.buildingContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px;
  align-items: center;
  justify-content: center;
}

.buildingText {
  font-size: 30px;
  text-transform: capitalize;
}

.buildingAction {
  padding-left: 30px;
}
