.listContainer {
  display: flex;
  width: 100%;
  border: 1px solid #f0f0f0;
  padding: 10px 20px;
  margin-bottom: 15px;
  margin-top: 25px;
}

.leftColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.rightColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.listItem {
  padding: 5px 0;
}

.tabsContainer {
  overflow: auto !important;
  margin: 0 20px 0 20px !important;
}
