.container {
    border-radius: 6px;
    background: #ffffff;
    box-shadow: 0 0 8px -5px #a4a4a4;
    padding: 5px 8px;
}

.title {
    margin-right: 10px;
    color: #52c41a;
}
