.typography {
  display: block;
  margin-bottom: 8px;
  color: black;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 5px 0;
}

.fields {
  margin-bottom: 14px;
}

.title {
  text-align: center;
}
