.contentComponent {
  margin-top: 10px;
}

.button {
  margin: 0 8px;
}

.contentContainer {
  display: flex;
  width: 100%;
  height: 100%;
}

.pagesListContainer {
  flex: 1;
  margin: 0 10px;
}

.tabsContainer {
  width: 100%;
  margin: 8px;
}
