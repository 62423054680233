// TODO - remove becouse of legacy

.root {
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;

  &:hover span {
    opacity: 1 !important;
  }
}

.labeledInput {
  flex: 1;
}

.fieldContainer {
  display: flex;
  align-items: center;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.image {
  width: 195px;
  height: 195px;
  margin-bottom: 10px;
  object-fit: contain;
  cursor: pointer;
}
