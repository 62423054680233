// TODO - remove becouse of legacy

.contentComponent {
  padding: 10px;
}

.contentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
}
