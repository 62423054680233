.container {
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.contentComponent {
  padding: 15px;
  overflow: auto;
  margin-bottom: 30px;
}

.twoColumnsContainer {
  display: flex;
  flex-direction: row;
}

.labeledItem {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 10px;
  margin-right: 20px;
}

.typography {
  color: #000;
  padding-bottom: 8px;
}

.footer {
  display: flex;
  justify-content: flex-end;

  button:nth-child(1) {
    margin-right: 10px;
  }
}

.activeSwitch {
  display: flex;

  article {
    margin-left: 10px;
  }
}

.mainImagePreviewContainer > div > div {
  width: 450px;
  height: 200px;
}

.bannerImagePreviewContainer > div > div {
  width: 450px;
  height: 200px;
}
