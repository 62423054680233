.input {
  outline: none;
  height: 32px;
  padding: 0 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-right: 8px;
}

.input:focus {
  border: 1px solid #1890ff;
}

.input:hover {
  border: 1px solid #1890ff;
}

.addButton {
  border: 1px solid #d9d9d9;
  width: 150px;
  text-align: center;
  padding: 2px;
  border-radius: 2px;
  background-color: transparent;
}

.addButton:focus {
  border: 1px solid #1890ff;
  color: #1890ff;
}

.addButton:hover {
  border: 1px solid #1890ff;
  color: #1890ff;
}
