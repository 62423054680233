.container {
  padding: 0 20px 20px;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0;
  width: 100%;
}

.button:not(:first-child) {
  margin-left: 10px;
}
