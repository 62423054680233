.container {
  padding: 0 20px 20px;
}

.table {
  user-select: none;
}

.storeProductsList {
  max-height: 100%;
  overflow-y: scroll;
}

.buttonAligin {
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
  gap: 10px;
}
