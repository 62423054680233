.settingsRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 8px;
}

.settingItem {
  display: flex;
  align-items: center;
}

.settingTitle {
  margin-right: 8px;
  font-weight: bold;
}

.saveButton {
  margin-right: 8px;
}
