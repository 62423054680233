.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}

.emptyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
}

.listContainer {
  display: flex;
  width: 100%;
  border: 1px solid #f0f0f0;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 25px;
}

.leftColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.rightColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.listItem {
  padding: 5px 0;
}

.listItemCampaign {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.customerForm {
  width: 200px;

  & > * {
    margin-bottom: 20px;
  }
}

.tabsContainer {
  overflow: auto !important;
}

.listItemTrack {
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
}

.listItemTrack:hover .editIcon {
  display: flex;
}

.editIcon {
  display: none;
}

.group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.groupItem {
  width: 49% !important;
}

.inputField {
  width: 100% !important;
}

.advancedButton {
  margin-bottom: 15px;
}
