.landingsSelectContainer {
  width: 400px;
  max-width: 400px;
}

div.rangePicker {
  width: 100%;
}

.extraContainer {
  display: flex;
}

.drawerContent {
  flex-grow: 1;
}

.drawerFooter {
  padding: 10px 16px;
  display: flex;
  justify-content: flex-end;
}

.label {
  display: block;
  margin: 12px 0 8px;
}

.okButton,
.filtersButton {
  margin-right: 15px;
}

.landingsSelector {
  width: 100%;
}

.title {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin: 10px 16px;
}
