.variantNameContainer {
  display: flex;
  flex-direction: column;
}

.variantKey {
  font-weight: bold;
}

.actionsContainer {
  display: flex;
  flex-direction: row;
}
