// TODO - remove becouse of legacy

.templatesContainer {
  display: flex;
  flex-direction: row;
}

.card {
  width: 100%;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  width: 300px;
}
