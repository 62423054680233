.contentComponent {
    padding: 15px;
    flex: 1;
    flex: inherit !important;
    overflow: auto;
}

.tableContainer {
    width: 100%;
    height: 100%;
    display: flex;
}
