.pageWizardContainer {
  display: flex;
  flex-direction: row;
}

.stepsContainer {
  padding: 20px 30px;
  width: 250px;
}

.content {
  width: 100%;
}

.card {
  width: 100%;
}
