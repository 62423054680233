.menu {
  transition-property: width !important;
  transition-duration: 0.3s, 0.2s !important;
  transition-timing-function: ease, ease !important;
  transition-delay: 0s, 0s !important;
}

.antMenu {
  margin-bottom: 20px !important;
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.avatarText {
  color: white !important;
  margin-top: 10px;
  margin-bottom: 20px;
}

.menuContainer {
  display: flex;
  background-color: #001529;
}

.activeCampaign {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  font-weight: bold;
  font-size: x-large;
  text-transform: capitalize;
  color: white !important;
}

.activeCampaignText {
  color: rgba(255, 255, 255, 0.65) !important;
  margin-right: 5px;
  font-size: 12px;
}
