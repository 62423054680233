// TODO - remove becouse of legacy

.contentComponent {
  padding: 15px;
  flex: 1 !important;
  overflow: auto;
  margin-bottom: 30px;
}

.quizFormBlock {
  padding: 20px 20px 0;
  border: 1px solid #dddddd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.quizFormRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.quizFormRowItem {
  width: 49%;
}

.quizFormPriceRow {
  display: flex;
}

.labeledItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-right: 20px;
}

.quizFormOptionBlock {
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.optionsContainer {
  position: relative;
}

.moreOptionBtn {
  width: 138px;
  color: black !important;
  margin-bottom: 20px;
  display: flex;
}

.previewBlockItem {
  display: flex;
  flex-direction: column;
}

.previewBlockItemTitle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.previewBlockItemName {
  width: 368px;
  padding: 7px 0px 7px 10px;
  background-color: #dddddd;
  margin-right: 10px;
}

.previewBlockItemRow {
  display: flex;
}

.createButton {
  margin-right: 8px;
}

span.deleteButton {
  cursor: pointer;

  &[data-required='true'] {
    opacity: 0;
    cursor: default;
  }
}

.attributesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-column-gap: 5px;
  grid-row-gap: 10px;
  align-items: center;
}

.buttonsContainer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
}

.baseContainer {
  position: relative;
}

.blockPage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(220, 220, 220, 0.95);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blockPageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.blockPageTitle {
  font-size: 20px;
  font-weight: 500;
  span {
    color: #1890ff;
    text-decoration: underline;
    cursor: pointer;
  }
}

.createSubscriptionContainer {
  margin-top: 24px;
}

.drawerFooter {
  padding: 10px 16px;
  display: flex;
  justify-content: flex-end;
}

.drawer {
  display: flex;
  flex-direction: column;

  article {
    font-size: 14px;
    margin-bottom: 8px;
    font-family: inherit;
  }
}

.cancelButton {
  margin-right: 8px;
}
