.overview-report-list {
  min-height: 100%;
  background: #fff;
}

.overview-report-list__page-header {
  border: 1px solid rgb(235, 237, 240);
}

.overview-report-listt__settings-field {
  margin-top: 10px;
}

.overview-report-list__add-button {
  text-transform: uppercase;
}

.overview-report-list__table-container {
  margin-top: 10px;
}

.overview-report-list__spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.overview-report-list__column-hidden {
  display: none;
}

.ant-table-row {
  font-weight: bold;
  background-color: #fafafa;
}
