.cancelButton {
    margin-right: 8px;
}

.divider {
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.85) !important;
}

.customerAccountSwitch {
    margin-top: 30px !important;
    margin-bottom: 6px !important;
}

.form {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.formItems {
    flex: 1;
    overflow: auto;
    padding: 24px;
}

.buttonsContainer {
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;
    text-align: right;
}

.warningMessage {
    color: #ff8c00;
}
