.tableContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  overflow: auto;
}

.cardTitle {
  font-weight: bold;
}
