.cardsContainer {
  display: flex;
  align-items: flex-start;
  margin: 0px 20px;
  justify-content: space-between;
}

.cardRate {
  width: calc(100% / 7);
}

.innerCardContainer {
  display: flex;
  align-items: flex-end !important;
}

.cardButton {
  margin-left: -60px !important;
}

.tableContainer {
  margin: 20px 15px;
}

.statusIcon {
  font-size: 20px;
}
