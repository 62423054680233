.closeButton {
  margin-right: 8px;
}

.miniForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.formItem {
  margin-top: 10px;
}
