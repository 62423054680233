.contentComponent {
  padding: 15px;
}

.search {
  width: 200px;
  margin-left: 10px;
}

.createButton {
  margin-left: 10px;
}

.editIcon {
  cursor: pointer;
  font-size: 20px;
}
