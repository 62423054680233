.contentComponent {
  padding: 10px;
}

.contentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

.actionIcons {
  display: flex;
  justify-content: space-around;
}

.card {
  width: 100%;
}

.closeButton {
  margin-right: 10px;
}