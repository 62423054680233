// TODO - remove becouse of legacy

.wizardDeployContainer {
  display: flex;
  flex-direction: row;
}

.card {
  width: 100%;
}

.closeButton {
  margin-right: 10px;
}

.contentContainer {
  display: flex;
  width: 100%;
  height: 100%;
}
